// import React, { lazy, Suspense } from 'react';
// import { BrowserRouter as Router } from 'react-router-dom';
// import { HelmetProvider } from 'react-helmet-async';


// import AnimatedRoutes from './Routes/AnimatedRoutes'; // Assuming AnimatedRoutes is a default export

// function App() {
//   return (
//     <HelmetProvider>
//       <Router>
//         <Suspense
//           fallback={
//             <div className="flex flex-col justify-center items-center h-screen">
//               <span className='text-xl font-bold text-vivamagenta-accent my-2'>Loading...</span>
//             </div>
//           }
//         >
//           <AnimatedRoutes />
//         </Suspense>
//       </Router>
//     </HelmetProvider>
//   );
// }

// export default App;
import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { GoogleOAuthProvider } from '@react-oauth/google'; // Import GoogleOAuthProvider

import AnimatedRoutes from './Routes/AnimatedRoutes'; // Assuming AnimatedRoutes is a default export

const clientId = 'YOUR_GOOGLE_CLIENT_ID'; // Replace with your actual Google Client ID

function App() {
  return (
    <GoogleOAuthProvider clientId={clientId}> {/* Wrap Router with GoogleOAuthProvider */}
      <HelmetProvider>
        <Router>
          <Suspense
            fallback={
              <div className="flex flex-col justify-center items-center h-screen">
                <span className='text-xl font-bold text-vivamagenta-accent my-2'>Loading...</span>
              </div>
            }
          >
            <AnimatedRoutes />
          </Suspense>
        </Router>
      </HelmetProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
