// ProductIdContext.js
import React, { createContext, useState, useContext } from "react";

// Create the context
const ProductIdContext = createContext();

// Create the context provider
export const ProductIdProvider = ({ children }) => {
  const [productId, setProductId] = useState(null);

  const setProductIdValue = (id) => {
    setProductId(id);
  };

  return (
    <ProductIdContext.Provider value={{ productId, setProductIdValue }}>
      {children}
    </ProductIdContext.Provider>
  );
};

// Custom hook to consume the context
export const useProductId = () => useContext(ProductIdContext);
