// import React from "react";
// import { Link } from "react-router-dom";
// import logo from "../assets/boguslogoAsset 1-8.png";
// import { FaHeart } from "react-icons/fa";
// import { FaFacebook,  FaInstagram, FaWhatsapp } from "react-icons/fa";
// import { FaSquareXTwitter } from "react-icons/fa6";
// import "../marquee.css";
// const SITEMAP = [
//   {
//     title: "HELP CENTER",
//     links: [
//       { label: "Help & Support", to: "/helpcenter" },
//       { label: "Terms & conditions", to: "/termsandcondition" },
//       { label: "Privacy Policy", to: "/privacypolicy" },
//       { label: "Shipping Policy", to: "/shippingpolicy" },
//       { label: "Refunds & Cancellation", to: "/returnpolicy" },
//     ],
//   },
//   {
//     title: "Social",
//     links: [
//       { 
//         label: "Facebook", 
//         url: "https://www.facebook.com/profile.php?id=100093089197019", 
//         icon: <FaFacebook className="text-white bg-blue-500  rounded-full" />, 
//         external: true 
//       },
//       { 
//         label: "Twitter", 
//         url: "https://twitter.com/MyAutomart2023", 
//         icon: < FaSquareXTwitter className="text-white   rounded-full" />, 
//         external: true 
//       },
//       { 
//         label: "Instagram", 
//         url: "https://www.instagram.com/automartshop", 
//         icon: <FaInstagram className="text-white bg-pink-400  rounded-full" />, 
//         external: true 
//       },
//       { 
//         label: "WhatsApp", 
//         url: "https://wa.link/vma6mc", 
//         icon: <FaWhatsapp className="text-white bg-green-500  rounded-full" />, 
//         external: true 
//       },
//     ],
//   },
//   {
//     title: "Registered Office",
//     links: [
//       { label: "Auto Mart" },
//       { label: "A109-Roongta Complex, Dwarka," },
//       { label: "Nashik, Maharashtra, India-422001" },
//       { label: "Email: info@auto-mart.co.in" },
//       { label: "Phone: +918530598477" },
//     ],
//   },
// ];

// const currentYear = new Date().getFullYear();

// export function FooterWithSitemap() {
//   const isMobileScreen = window.innerWidth <= 768;
//   if (isMobileScreen) {
//     return null;
//   }

//   return (
//     <footer className="relative w-full  text-white" style={{ backgroundColor: '#2F1532' }}>
//       <div className="mx-auto w-full max-w-7xl px-8">
//         <div className="grid w-full grid-cols-1 gap-8 py-12 md:grid-cols-2 md:justify-center lg:grid-cols-4">
//           {SITEMAP.map(({ title, links }, index) => (
//             <div key={index} className="md:w-full text-center">
//               <div className="mb-4 font-bold bg-vivamagenta-accent uppercase">
//                 {title}
//               </div>
//               <ul className="space-y-1">
//                 {links.map((link, linkIndex) => (
//                   <li key={linkIndex} className="font-normal">
//                     {link.external ? (
//                       <a
//                         href={link.url}
//                         target="_blank"
//                         rel="noopener noreferrer"
//                         className="inline-flex items-center space-x-2 py-1 pr-2 transition-transform hover:scale-105"
//                       >
//                         {link.icon} 
//                         <span>{link.label}</span>
//                       </a>
//                     ) : (
//                       <Link
//                         to={link.to || ""}
//                         className="inline-block py-1 pr-2 transition-transform hover:scale-105"
//                       >
//                         {link.label}
//                       </Link>
//                     )}
//                   </li>
//                 ))}
//               </ul>
//             </div>
//           ))}
//           <div className="justify-around top-4 bg-blue-50 text-center p-12" style={{borderRadius:'60%'}} >
//             <img src={logo} alt="Automart Logo" className=""/>
//           </div>
//         </div>
//         <div className="flex w-full flex-col items-center justify-center border-t border-blue-gray-50 py-4 md:flex-row md:justify-between">
//           <div className="mb-4 text-center font-normal text-blue-100 md:mb-0">
//             &copy; {currentYear} <span>Automart</span>. All Rights Reserved.
//           </div>
//           <div className="flex space-x-1">
//             <h2 className="text-blue-100 flex">
//               Made With <FaHeart className="text-red-500 mt-1 mx-1 animate-pulse" /> In India
//             </h2>
//           </div>
//           <div className="flex gap-4 text-blue-gray-900 sm:justify-center">
//             <p className="text-sm text-blue-100">
//               Developed By ShivKalp IT Services.
//             </p>
//           </div>
//         </div>
//       </div>
//     </footer>
//   );
// }


import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import logo from "../assets/boguslogoAsset 1-8.png";
import { FaHeart } from "react-icons/fa";
import { FaFacebook, FaInstagram, FaWhatsapp } from "react-icons/fa";
import { FaSquareXTwitter } from "react-icons/fa6";
import "../marquee.css";

const SITEMAP = [
  {
    title: "HELP CENTER",
    links: [
      { label: "Help & Support", to: "/helpcenter" },
      { label: "Terms & conditions", to: "/termsandcondition" },
      { label: "Privacy Policy", to: "/privacypolicy" },
      { label: "Shipping Policy", to: "/shippingpolicy" },
      { label: "Refunds & Cancellation", to: "/returnpolicy" },
    ],
  },
  {
    title: "Social",
    links: [
      {
        label: "Facebook",
        url: " https://www.facebook.com/profile.php?id=61566840877281 ",
        icon: <FaFacebook className="text-white bg-blue-500 rounded-full" />,
        external: true,
      },
      {
        label: "Twitter",
        url: "https://x.com/Automart2024  ",
        icon: <FaSquareXTwitter className="text-white rounded-full" />,
        external: true,
      },
      {
        label: "Instagram",
        url: "https://www.instagram.com/automartshop",
        icon: <FaInstagram className="text-white bg-pink-400 rounded-full" />,
        external: true,
      },
      {
        label: "WhatsApp",
        url: "https://wa.link/vma6mc",
        icon: <FaWhatsapp className="text-white bg-green-500 rounded-full" />,
        external: true,
      },
    ],
  },
  {
    title: "Registered Office",
    links: [
      { label: "Auto Mart" },
      { label: "A109-Roongta Complex, Dwarka,",
        url:" https://maps.app.goo.gl/iYGNrEcZSQ2LXV16A", // Change made here
        external: true,
       },
      { label: "Nashik, Maharashtra, India-422001",
        url: "https://maps.app.goo.gl/iYGNrEcZSQ2LXV16A", // Change made here
        external: true,
       },
      {
        label: "Email: info@auto-mart.co.in",
        url: "mailto:info@auto-mart.co.in", // Change made here
        external: true,
      },
      {
        label: "Phone: +918530598477",
        url: "tel:+918530598477", // Change made here
        external: true,
      },
    ],
  }
  // {
  //   title: "Dummy Column", // Added a dummy column
  //   links: [
  //     { label: "Coming Soon" },
  //     { label: "Feature 1" },
  //     { label: "Feature 2" },
  //     { label: "Feature 3" },
  //   ],
  // },
];

const currentYear = new Date().getFullYear();

export function FooterWithSitemap() {
  const [isMobileScreen, setIsMobileScreen] = useState(window.innerWidth <= 768);

  // Update isMobileScreen on window resize
  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);

    // Cleanup the event listener
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // Return null if it's a mobile screen
  if (isMobileScreen) {
    return null;
  }

  return (
    <footer className="w-full text-white bg-[#2F1532] mt-10" >
      <div className="relative">
        {/* Centered logo with adjusted positioning */}
        <div className="bg-blue-50" style={{ position: 'absolute', top: '0', left: '50%', transform: 'translateY(-50%) translateX(-50%)', borderRadius: '50%', overflow: 'hidden',  padding: '20px' }}>
          <img
            src={logo}
            alt="Automart Logo"
            className="object-contain w-20 h-20 lg:w-24 lg:h-24"
            // Position above the top of the main div
          />
        </div>

        {/* Footer content grid */}
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 ">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8 py-8 text-center ">
            {SITEMAP.map(({ title, links }, index) => (
              <div key={index}>
                <h3 className="mb-4 mt-14 font-bold uppercase text-white bg-vivamagenta-accent">{title}</h3>
                <ul className="space-y-2">
                  {links.map((link, linkIndex) => (
                    <li key={linkIndex}>
                      {link.external ? (
                        <a
                          href={link.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="inline-flex items-center space-x-2 transition-transform hover:scale-105"
                        >
                          {link.icon} <span>{link.label}</span>
                        </a>
                      ) : (
                        <Link
                          to={link.to || ""}
                          className="inline-block transition-transform hover:scale-105"
                        >
                          {link.label}
                        </Link>
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row items-center justify-between border-t border-gray-700 py-4 text-sm text-center">
          <div className="mb-4 md:mb-0 text-gray-400">
            &copy; {currentYear} <span>Automart</span>. All Rights Reserved.
          </div>
          <div className="flex items-center text-gray-400">
            <span>Made With</span> <FaHeart className="text-red-500 mx-1" /> <span>In India</span>
          </div>
          <div className="text-gray-400">Developed By ShivKalp IT Services</div>
        </div>
      </div>
    </footer>
  );
}
