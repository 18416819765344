import React, { createContext, useContext, useState } from "react";

const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [totalProducts, setTotalProducts] = useState(0);

  const updateTotalProducts = (count) => {
    setTotalProducts(count);
  };

  return (
    <CartContext.Provider value={{ totalProducts: totalProducts || 0, updateTotalProducts }}>
    {children}
  </CartContext.Provider>
  
  );
};

export const useCart = () => {
  return useContext(CartContext);
};
