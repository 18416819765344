import React, { useState, useEffect, Fragment } from "react";
import axios from "axios";
import { motion } from "framer-motion";
import { CgSearch } from "react-icons/cg";
import { Listbox, Transition } from '@headlessui/react';
import { RxCaretDown, RxChevronUp } from 'react-icons/rx';
import { CheckIcon } from '@heroicons/react/20/solid';
// import {   FaTools } from 'react-icons/fa';  // Added icons
import { GiMechanicGarage } from 'react-icons/gi';
import { useNavigate } from "react-router-dom";
// import { FaShop } from 'react-icons/fa6';
import { HiOutlineShoppingBag } from "react-icons/hi2";
import { IoCarSport } from "react-icons/io5";
import { MdOutlineEngineering } from "react-icons/md";


// Define the category options with their respective icons
const options = [
  { id: "Shop", name: "Shop", icon: <HiOutlineShoppingBag className="text-lg" /> },
  { id: "Reuse", name: "Reuse", icon: <IoCarSport className="text-lg" /> },
  { id: "Garages", name: "Garages", icon: <GiMechanicGarage className="text-lg" /> },
  { id: "Technicians", name: "Technicians", icon: <MdOutlineEngineering className="text-lg" /> },
];

const CustomSelect = ({ selectedOption, onChange }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <Listbox value={selectedOption} onChange={onChange}>
      {({ open }) => {
        if (open) {
          setIsDropdownOpen(true);
        } else {
          setIsDropdownOpen(false);
        }

        return (
          <>
            <Listbox.Button
              className="relative lg:pl-2 lg:pr-5 md:pr-10 w-28 text-left bg-white rounded-full lg:w-32"
              onBlur={() => setIsDropdownOpen(false)}
            >
              <span className="block text-center flex items-center justify-center">
  {/* Display the selected category icon and name */}
  {options.find((option) => option.id === selectedOption).icon}
  <span className="ml-2 overflow-hidden whitespace-nowrap text-ellipsis max-w-[0ch] sm:max-w-[10ch]">
    {selectedOption}
  </span>
</span>
              <span className="absolute inset-y-3 right-1 top-2 lg:top-3 items-center">
                {(isDropdownOpen === 'true' || open) && (
                  <RxChevronUp className="text-gray-700 text-xl md:text-2xl" />
                )}
                {!isDropdownOpen && !open && (
                  <RxCaretDown className="text-xl md:text-2xl text-gray-700" />
                )}
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              as={Fragment}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                static
                className="absolute z-10 mt-11 lg:mt-14 rounded w-32 bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-start"
              >
                {options.map((option) => (
                  <Listbox.Option
                    key={option.id}
                    className={({ active }) =>
                      `${active ? "text-white bg-vivamagenta-accent" : "text-gray-900"} cursor-default select-none relative py-2 pl-8 pr-2 flex items-center`
                    }
                    value={option.id}
                  >
                    {({ selected, active }) => (
                      <>
                        {/* Display the icon and name in the dropdown */}
                        {option.icon}
                        <span className={`${selected ? "font-medium ml-2" : "font-normal ml-2"} block truncate`}>
                          {option.name}
                        </span>
                        {selected && (
                          <span className={`${active ? "text-white" : "text-vivamagenta-accent"} absolute inset-y-0 left-0 flex items-center pl-2`}>
                            <CheckIcon className="h-5 w-5" />
                          </span>
                        )}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </>
        );
      }}
    </Listbox>
  );
};

const SearchBar = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("Shop");
  const navigate = useNavigate();

  const handleCategoryChange = (category) => {
    setSearchTerm("");
    setSelectedCategory(category);
  };

  // Function to fetch search results
  const fetchResults = async () => {
    try {
      const response = await axios.get(
        `https://api.auto-mart.co.in/api/get/search/${selectedCategory}/${searchTerm}`,
        {
          headers: {
            Authorization: `${process.env.REACT_APP_API_KEY}`,
          },
        }
      );
      const results = response.data;
      navigate('/major', { state: { searchResults: results, selectedCategory } });
    } catch (error) {
      console.error("Error fetching search results:", error);
    }
  };

  const handleEnterPress = async () => {
    if (searchTerm.length >= 3) {
      await fetchResults();
    }
  };

  useEffect(() => {
    // Debounce for input typing only, not button click
    const handler = setTimeout(() => {
      if (searchTerm.length >= 3) {
        fetchResults();
      }
    }, 600); // 1-second debounce

    return () => {
      clearTimeout(handler);
    };
  }, [searchTerm, selectedCategory]);

  return (
   <motion.div
  initial={{ opacity: 0, y: -20 }}
  animate={{ opacity: 1, y: 0 }}
  exit={{ opacity: 0, y: -20 }}
  transition={{ duration: 0.5 }}
  className="flex bg-white mt-2 h-8 sm:h-8 md:h-10 lg:h-12 sm:ml-8 rounded-full"
  style={{ maxWidth: window.innerWidth < 640 ? '60%' : '100%'}}
>
  <CustomSelect
    selectedOption={selectedCategory}
    onChange={handleCategoryChange}
  />

  <div className="border-0 focus:outline-none focus:ring focus:ring-none mt-1 mb-3 relative lg:h-10 lg:p-1.5">
    <input
      className="w-[80%] sm:w-[70%] md:w-[60%]  border-0 focus:outline-none bg-transparent" 
      type="search"
      placeholder={`Search ${selectedCategory}`}
      value={searchTerm}
      onChange={(e) => setSearchTerm(e.target.value)}
      onKeyDown={(e) => {
        if (e.key === "Enter") {
          handleEnterPress();
        }
      }}
    />
    <div className="relative">
      <button
        className="absolute right-1 -top-2 lg:mb-7   transform -translate-y-1/2 font-bold text-vivamagenta-accent "
        onClick={() => {
          if (searchTerm.length >= 3) {
            fetchResults(); // Bypass debounce and fetch results immediately
          }
        }}
      >
        <CgSearch className="text-2xl sm:text-3xl pr-1 pt-2 mb-3 md:mt-2 lg:pt-0 " /> {/* Remove margin here */}
      </button>
    </div>
  </div>
</motion.div>


  );
};

export default SearchBar;
