// import React, { useEffect } from 'react';
// import { Cookies } from 'react-cookie';
// import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import { useCart } from '../ExcessCode/CartContext';

function CartCount() {
  // const cookies = new Cookies();
  // const token = cookies.get('token');
  const { updateTotalProducts } = useCart();

  // useEffect(() => {
  //   const cartcountfn = () => {
  //     if (token) {
  //       try {
  //         const decoded = jwtDecode(token);
  //         const userEmail = decoded.email;

  //         axios
  //           .get(`https://api.auto-mart.co.in/api/get/cartcount/${userEmail}`, {
  //             headers: {
  //               Authorization: `Bearer ${token}`,
  //             },
  //           })
  //           .then((response) => {
  //             const productsCount = response.data.count;
  //             updateTotalProducts(productsCount);
  //           })
  //           .catch((error) => {
  //             console.error('Error fetching cart count', error);
  //           });
  //       } catch (decodeError) {
  //         console.error('Error decoding token', decodeError);
  //       }
  //     } else {
  //       // If token is null, set the count to 0
  //       updateTotalProducts(0);
  //       console.error('Token is null. Cart count set to 0.');
  //     }
  //   };

  //   cartcountfn();
  // }, [token, updateTotalProducts]);

  return null; // You may return something meaningful here based on your use case
}

export default CartCount;
